<template>
    <v-container fluid fill-height class="home-hero" style="max-height: 100vh;">
        
        <!-- <video playsinline autoplay muted loop poster='../assets/img/Form1.png'>
        <source :src='require("../assets/Videos/saturday.mp4")' type='video/mp4'>
        </video> -->
        <v-layout justify-center align-center column pa-5>
            <div class="display-4 font-weight-black white--text text-xs-center">REGIMENTAL ACHIEVEMENTS</div>
            <div class="display-4 font-weight-black white--text text-xs-center mb-3">2007 to TODAY</div>
            <div class="display-1 font-weight-bold white--text text-xs-center">A look into our history over the regiments lifetime</div>
            
        </v-layout>
        <!-- <video autoplay muted loop poster='../assets/img/Form1.png' id="myVideo">
        <source src='require("../assets/Videos/saturday.mp4")' type="video/mp4">
        </video> -->
        
    </v-container>
</template>

<script>
export default {
    name: 'SAcheivements'
};
</script>

<style scoped>
.home-hero {
    background: url('https://i.imgur.com/JTp20p3.png');
    background-size: cover;
    width: 100%;
    height: 100%;
}
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
</style>
