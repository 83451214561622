<template>
    <span>
        <v-navigation-drawer app v-model="drawer" class="brown lighten-2" dark disable-resize-watcher>
            <v-list>
                <template v-for="(item, index) in items">
                    <v-list-tile :key="index">
                        <v-list-tile-content>
                            {{item.title}}
                        </v-list-tile-content>
                    </v-list-tile>
                    <v-divider :key="`divider-${index}`"></v-divider>
                </template>
            </v-list>
        </v-navigation-drawer>
        <v-toolbar app color="blue darken-3" dark>
            <v-toolbar-side-icon class="hidden-md-and-up" @click="drawer = !drawer"></v-toolbar-side-icon>
            <v-spacer class="hidden-md-and-up"></v-spacer>
            
             <v-toolbar-title class="" > <v-img src="../assets/img/52st.png" class="mt-" contain height="50px">  </v-img> </v-toolbar-title>
             <h2>{{appTitle}}</h2>
             

             <v-spacer class="hidden-sm-and-down"></v-spacer>
             
            <v-btn flat color="blue lighten-4" class="hidden-sm-and-down; black--text mr-5" justify="center" to="/">Home</v-btn>
            
            <v-btn flat color="blue lighten-4" class="hidden-sm-and-down; black--text" align="center" to="/satac">SATAC</v-btn>
            <!-- <v-btn flat class="hidden-sm-and-down" to="/sign-in">SIGN IN</v-btn>
            <v-btn color="brown lighten-3" class="hidden-sm-and-down" to="/join">JOIN</v-btn> -->
        </v-toolbar>
    </span>
</template>
<script>
export default {
    name: 'AppNavigation',
    data() {
        return {
            appTitle: '51st PVO/100th KIAP  ',
            drawer: false,
            items: [
                { title: 'Satac' },
                { title: 'Profile' }, 
                { title: 'Sign In' },
                { title: 'Join' }
            ]
        };
    }
};
</script>
<style scoped>
</style>