<template>
<v-carousel
    cycle
    height="1400"
    interval="10000"
    hide-delimiter-background
    show-arrows-on-hover
    reverse-transition="fade-transition"
    transition="fade-transition"
  >
    <v-carousel-item
      v-for="(slide, i) in slides"
      :key="i"
    >
    <v-container fluid fill-height :class="slide.class" style="max-height: 100vh;">
        
        <!-- <video playsinline autoplay muted loop poster='../assets/img/Form1.png'>
        <source :src='require("../assets/Videos/saturday.mp4")' type='video/mp4'>
        </video> -->
        <v-layout justify-center align-center column pa-5 pt-16 mt-16>
            <div class="display-4 font-weight-black white--text text-xs-center pt-16 mt-16">{{slide.mainTxt}}</div>
            <div class="display-3 font-weight-black white--text text-xs-center mb-3">{{slide.subTxt}}</div>
            <div class="display-1 font-weight-bold white--text text-xs-center">{{slide.desc}}</div>
            <v-btn fab class="mt-5 brown darken-4">
            <a :href= "slide.link"><v-icon large color="white">{{slide.icon}}</v-icon></a>
            </v-btn>
        </v-layout>
        <!-- <video autoplay muted loop poster='../assets/img/Form1.png' id="myVideo">
        <source src='require("../assets/Videos/saturday.mp4")' type="video/mp4">
        </video> -->
        
    </v-container>

      </v-carousel-item>
  </v-carousel>



</template>

<script>
export default {
    name: 'HomeHero',
    //test
    data() {
        return {
            colors: [
            'indigo',
            'warning',
            'pink darken-2',
            'red lighten-1',
            'deep-purple accent-4',
            ],
            slides: [
            {
                class: 'home-hero',
                mainTxt: "100th KIAP / 51st PVO",
                subTxt: "Flanker Regiment",
                desc: "Flying the Flanker in DCS at the highest competitive level since 2007",
                link:"",
                icon:"mdi-arrow-down-circle-outline",
            },
            {
                class: 'satac-hero',
                mainTxt: "Launch of SATAC 2021",
                subTxt: "The premier air to air championship returns",
                desc: "Returning January, we will see the tops teams battle it out for victory and honour",
                link:"https://discord.gg/TPEt8ZYm",
                icon:"mdi-discord",
            },
            {
                class: 'twitch-hero',
                mainTxt: "Inside the mind of Stalin",
                subTxt: "Come watch 51st Breakshot take on all DCS has to offer",
                desc: "Whether you come to learn or to simply enjoy the beauty of the Flanker , all are welcome",
                link:"https://www.twitch.tv/51breakshot",
                icon:"mdi-twitch",
            },
            
            {
                class: 'sd10-hero',
                mainTxt: "Victory at House of SARH",
                subTxt: "Hard fought victory against 104th in House of SARH Finals",
                desc: "After a great tournament run by [E-TF]Cookie, KIAP took home the trophy and is now looking forward to participating again in the new year!",
                link:"https://discord.com/channels/877089937416142869/877213662601953390/893979399093911562",
                icon:"mdi-alarm-light",
            },
            {
                class: 'spqr-hero',
                mainTxt: "SATAC servers, Hosted by SPQR",
                subTxt: "51stKIAP is very gracious about SPQR support in hosting SATAC-2021",
                desc: "Check out their PVP server SPQR Colosseum and the other great events they run on their discord! ",
                link:"https://discord.gg/xWZTBqBh",
                icon:"mdi-discord",
            },
            ],
        }


    }
};
</script>

<style scoped>
.home-hero {
    background: url('https://i.imgur.com/ZtGKP6K.png');
    background-size: cover;
    width: 100%;
    height: 100%;
    
}
.twitch-hero {
    background: url('../assets/img/Form6.png');
    background-size: cover;
    width: 100%;
    height: 100%;
}
.satac-hero {
    background: url('https://media1.giphy.com/media/rXtMujLtobaCuYKDZv/giphy.gif?cid=790b76117bdc51540b32e5a1de38d7206fa8baaefecdcb91&rid=giphy.gif&ct=g');
    background-size: cover;
    width: 100%;
    height: 100%;
}
.sd10-hero {
    background: url('../assets/img/Relaxation.png');
    background-size: cover;
    width: 100%;
    height: 100%;
}
.spqr-hero {
    background: url('https://media2.giphy.com/media/6DQC3Qm9IAm7YKnrhr/giphy.gif?cid=790b76111127696d3269428908ad974043f541654f4f2e44&rid=giphy.gif&ct=g');
    background-size: cover;
    width: 100%;
    height: 100%;
}

</style>
