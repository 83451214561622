<template>
    <v-container>
        <v-layout column>
            <v-flex  class="display-2 text-xs-center my-5">About the Squadron</v-flex>
            <v-flex>
                <div class="headline mt-3">51st "Bisons" PVO Regminet</div>
                <p class="subheading mt-3">51st "Bisons" PVO Regiment - In 2007, a few dedicated Lock On pilots decided that the time was right to shape a true virtual REDFOR squadron, with new ideas, unique culture, a competitive approach and establish world-wide recognition. On 15-Dec-2007 it was officially inaugurated as the 51st PVO Regiment to honor a real Soviet historical squadron that flew the venerable Su-27 & Mig-29 fighters out of Krymsk AFB. The mighty bison, a native regional wild animal, was proudly adopted as the squadron's icon. On 1-Mar-2008 51 PVO Regiment was declared combat ready. It is one of the oldest squads in DCS with over a decade of complete domination in competition and community events.</p>
            </v-flex>
            <v-flex>
                <div class="headline mt-3">100 KIAP "Black Marlins"</div>
                <p class="subheading mt-3">100 KIAP "Black Marlins" - Flying the Su-33 Flanker from Admiral Kuznetsov class aircraft carrier, honoring the very first Soviet Flanker naval regiment based in Crimea. On 15-June-2019 the 51st integrated with 100th Virtual Shipborne Fighter Aviation Regiment - now operating under one command structure. This naval regiment is home to the very best pilots of the combined roster, with the highest mission qualifications and combat experience.</p>
            </v-flex>
            <!-- <v-flex>
                <div class="headline mt-3">Paragraph to be determined</div>
                <p class="subheading mt-3">Nullam nec massa eu est fringilla lobortis. Praesent in enim in justo blandit varius. Cras placerat arcu in sapien rhoncus aliquet. Sed interdum tortor et tincidunt condimentum. Etiam consequat mi leo, in suscipit odio scelerisque molestie. Nam et purus consequat, iaculis augue vel, sagittis ligula. Vestibulum aliquet vulputate erat. Phasellus id mauris mauris. Nunc a maximus dolor. Curabitur ut vestibulum arcu. Curabitur non lacus justo. Cras varius a magna in semper. Nulla eros ante, consectetur faucibus sapien eu, rhoncus imperdiet dui. Sed viverra iaculis nunc, id pulvinar massa egestas vitae.</p>
            </v-flex> -->
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'HomeDetails'
};
</script>

<style scoped>
</style>