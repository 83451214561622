<template>
  <v-footer
    dark
    padless
    bottom
    fixed
  >
    <v-card
      class="flex"
      flat
      tile
    >
      
     


      <v-card-text class="py-2 white--text text-center">
        {{ new Date().getFullYear() }} — <strong>CB Consultancy | Come Join us for a drink at the bar! --></strong>
         <v-btn
          v-for="icon in icons"
          :key="icon"
          class="mx-4"
          dark
          icon
          :href="icon.link"
          target="_blank">
          <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-icon size="24px"
        v-bind="attrs"
          v-on="on">
             {{ icon.icon }}
          </v-icon>
      </template>
      <span>{{icon.title}}</span>
    </v-tooltip>
        
          
        </v-btn>
      </v-card-text>
    </v-card>
  </v-footer>
</template>
<script>
  export default {
    data: () => ({
      icons: [
         {
             icon: 'mdi-discord',
             link: "https://discord.gg/4S3EWChmhg",
             title: "100KIAP"
         },
         {
             icon: 'mdi-discord',
             link: "https://discord.gg/whFrfwZT59",
             title: "51st PVO"
         },
         {
             icon:'mdi-twitch',
             link:"https://twitch.tv/51breakshot",
             title: "51stBreakshot"
         },
         {
             icon:'mdi-youtube',
             link:"https://www.youtube.com/channel/UCyOjJbNBpV3yMmek0VmoU3w"
         },
        
        
      ],
    }),
  }
</script>