<template>
    <v-container>
        <v-timeline>
    <v-timeline-item
      v-for="(year, i) in years"
      :key="i"
      :color="year.color"
      small
    >
      <template v-slot:opposite>
        <span
          :class="`headline font-weight-bold ${year.color}--text`"
          v-text="year.year"
        ></span>
      </template>
      <div class="py-4">
        <h2 :class="`headline font-weight-light mb-4 ${year.color}--text`">
          {{year.title}}
        </h2>
        <div>
          51st PVO/100 KIAP Victory
        </div>
      </div>
    </v-timeline-item>
  </v-timeline>

      
    </v-container>
</template>

<script>
export default {
    name: 'historyTime',
    data: () => ({
      years: [
        {
          color: 'cyan',
          year: '2011',
          title: "SATAC",
          text: "",
        },
        {
          color: 'green',
          year: '2013',
          title: "SATAC",
          text: "",
        },
        {
          color: 'pink',
          year: '2015',
          title: "SATAC",
          text: "",
        },
        {
          color: 'amber',
          year: '2017',
          title: "SATAC",
          text: "",
        },
        {
          color: 'orange',
          year: '2018',
          title: "SATAC",
          text: "",
        },
        {
          color: 'blue',
          year: '2021',
          title: "Mutual Support 2v2",
          text: "",
        },
        {
          color: 'red',
          year: '2021',
          title: "House of SARH",
          text: "",
        },
      ],
    }),
};
</script>

<style scoped>
</style>