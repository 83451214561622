<template>
    <v-app>
        <app-navigation></app-navigation>
        
        <v-content transition="slide-x-transition">
            <router-view></router-view>
            
        </v-content>
        <foot></foot>
    </v-app>
</template>

<script>
import AppNavigation from '@/components/AppNavigation';
import Foot from '@/components/Footer';
document.title = "100KIAP/51stPVO";

export default {
    
    name: 'App',
    components: {
        AppNavigation,
        Foot
    },

    watch: {
      '$route' (to, from) {
          console.log(from)
        document.title = to.meta.title || 'Your Website'
      }
    },
};
</script>

<style>
</style>