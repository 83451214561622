<template>
    <v-container fluid fill-height class="home-hero" style="max-height: 100vh;">
        
        <!-- <video playsinline autoplay muted loop poster='../assets/img/Form1.png'>
        <source :src='require("../assets/Videos/saturday.mp4")' type='video/mp4'>
        </video> -->
        <v-layout justify-center align-center column pa-5>
            <div class="display-4 font-weight-black white--text text-xs-center">SATAC 2021</div>
            <div class="display-4 font-weight-black white--text text-xs-center mb-3">Squadron Air to Air Championship</div>
            <div class="display-1 font-weight-bold white--text text-xs-center">Bringing back a fair and prestigious platform for competitive Air to Air sportsmanship</div>
            <v-btn fab class="mt-5 brown darken-4">
                <a href="https://discord.gg/whFrfwZT59"><v-icon large color="white">mdi-discord</v-icon></a>
            
            </v-btn>
        </v-layout>
        <!-- <video autoplay muted loop poster='../assets/img/Form1.png' id="myVideo">
        <source src='require("../assets/Videos/saturday.mp4")' type="video/mp4">
        </video> -->
        
    </v-container>
</template>

<script>
export default {
    name: 'SHistory'
};
</script>

<style scoped>
.home-hero {
    background: url('https://media1.giphy.com/media/rXtMujLtobaCuYKDZv/giphy.gif?cid=790b76117bdc51540b32e5a1de38d7206fa8baaefecdcb91&rid=giphy.gif&ct=g');;
    background-size: cover;
    width: 100%;
    height: 100%;
}
#myVideo {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
</style>
