<template>
    <v-container>
        <v-layout column>
            <v-flex  class="display-2 text-center my-5">SATAC 2021</v-flex>
            <v-img src="../assets/img/sataclogo.png" 
                contain    
                height="400px">  
                        
                </v-img>
                
            <v-card class ="pt-16" elevation="0"  >
            <v-img src="../assets/img/Sponsors.png"  class ="p-20" contain  height="230px" > 
            
                        
                </v-img>
                </v-card>
            <v-flex>
                <div class="headline text-center mt-3 pt-16">SATAC-2021 Details</div>
                <p class="subheading text-center mt-3">The 51st PVO & 100th KIAP are proud to announce SATAC 2021 - which will take place from October through December. Once a year, the 51st PVO stages a Championship called SATAC (Squadron Air to Air Championship). SATAC's current focus is to bring back a fair and prestigious platform for competitive Air to Air sportsmanship; where all squadrons/airframes are invited.</p>

                <p class="subheading text-center mt-3">This event dates back to 2011, when the first SATAC was organised. We would like to keep up this tradition by inviting all competitive Air to Air squads to fly against the best DCS has to offer in the most immersive and realistic combat environment. With the format below, we hope to encourage the use of all weapon types (ARH/SARH/IR), dissimilar air combat tactics (DACT), and for all modern DCS aircraft to play their unique roles in the event.</p>

                <p class="subheading text-center mt-3">The event is expected to continue for no longer than 3 months, depending on the number of participating squads.</p>

                <p class="subheading text-center mt-3">You can keep up with the current state of SATAC-2021 through the SATAC page of the website or through the SATAC-2021 <a href="https://discord.gg/TPEt8ZYm">discord</a>.</p>
            </v-flex>
            <v-flex>
                <div class="headline text-center mt-3">Weather</div>
                <p class="subheading text-center mt-3">Variable weather: Clouds, wake, wind, and time of day. Selected by dice roll during scheduling.</p>
                
            </v-flex>
            
            <v-flex>
                <div class="headline text-center mt-3">Sponsors</div>
                <p class="subheading text-center mt-3">1st place team will win the free choice of six unreleased/released modules from each sponsor supporting SATAC 2021.</p>
                <p class="subheading text-center mt-3">2nd and 3rd place teams will win the free choice of six released modules from each sponsor supporting SATAC 2021.</p>
                <p class="subheading text-center mt-3">Squads that reach the knock-out phase will win one key from LotAtc and Tacview.</p>
            </v-flex>
            <v-flex>
                <div class="headline text-center mt-3">Prizes</div>
                <p class="subheading text-center mt-3">The 51st PVO & 100th KIAP are sincerely grateful to all those who are supporting SATAC 2021!</p>
                <p class="subheading text-center mt-3">Eagle Dynamics, Magnitude 3, Razbam, Heatblur, Tacview, LotATC.</p>
            </v-flex>
            <v-flex>
                <div class="headline text-center mt-3">Format</div>
                <p class="subheading mt-3">
                    <ul>
                    <li>6 vs 6, thus we highly recommend having reserves. Expect to fly shorthand against a fully rostered opposition if you don't have 6 pilots ready by match time.</li>
                    <li>A mercenary reserve pool is currently being considered for teams short on pilots. If this sounds interesting to you, put your name forward in the merc-bar in the SATAC 2021 Discord</li>
                    <li>Squadrons can field more than one team if they wish</li>
                    <li>All fourth-generation aircraft will be made available: F-14, F-15, F-16, F-18, JF-17, M2000, MIG-29, Su-27, Su-33, J-11.</li>
                    <li>Airframe selection is permanent during individual matches. Teams must choose a set line up for each game and stick with those aircraft. You may not change the airframe line up between rounds of a match. E.g. choosing 4xF15, 2xSu27 cannot be altered until the next match.</li>
                    <li>There will be an engagement bubble centred on bullseye, which opposing squads must control to win the round. Once you enter the bubble, you must not exit until the opposing team has been neutralised. Stay within the engagement bubble till the opposition is destroyed. Once you leave the bubble, you cannot re-enter. You will be warned as you approach the edge of the bubble.</li>
                    <li>As an aircraft enters the bubble, a 5-minute timer will begin where all aircraft must be inside the bubble in that time. Any team with aircraft outside the bubble after the 5 minutes will forfeit the round.</li>
                    <li>At least one pilot from the winning team must return to the home base in order to claim victory for that round.</li>
                    <li>If aircraft take off with an invalid weapon and then fires said weapon or enters the bubble, that round is forfeit for the offending team.</li>
                    <li>RTB's are allowed to correct mistakes with payload or aircraft systems as long as said aircraft hasn't entered the bubble.</li>
                    <li>Disconnected players are allowed to re-join and take off again as long as his/her's aircraft did not enter the bubble.</li>
                    <li>Matches will be best of 3 in the preliminary rounds, and best of 5 in knockout rounds.</li>
                    <li>AWACS and datalink support will be available during preliminary rounds only.</li>
                    <li>No datalink or non-player BRAA calls during knockout rounds. Qualifying teams will win a LotAtc key (https://www.lotatc.com/) courtesy of DArt and will need to register a GCI officer.</li>
                    <li>A neutral GCI officer will be provided if a squad is unable to field one.</li>
                    <li>Communications with the GCI officer will be handled through SRS.</li>
                    <li>We can not define every rule due to DCS' constant updates and complexities. Teams should refrain from doing anything that may be considered unsporting or not to the spirit of competition.</li>
                    <li>Maximum: 4x Active radar homing missiles (all types), 4x R-27ER's.</li>
                    <li>No AIM-9X, No Air to Ground weapons (The F-14's TALD's are exempt from this).</li>
                    </ul>
                </p>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: 'HomeDetails'
};
</script>

<style scoped>
</style>