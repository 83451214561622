<template>
    <span>
        <home-hero></home-hero>
        <home-details></home-details>
        <satac></satac>
        <satac-details></satac-details>
        <acheive></acheive>
        <time-line></time-line>
        <home-plans></home-plans>
    </span>
</template>

<script>

import HomeHero from '@/components/SHomeHero';
import Satac from '@/components/SSATAC21';
import Acheive from '@/components/SAcheivements';
import HomeDetails from '@/components/THomeDetails';
import HomePlans from '@/components/HomePlans';
import SatacDetails from '@/components/TSATACDetails';
import TimeLine from '@/components/Timeline';



export default {
    name: 'home',
    components: {
        HomeHero,
        HomeDetails,
        HomePlans,
        Satac,
        Acheive,
        SatacDetails,
        TimeLine,
    }
};
</script>